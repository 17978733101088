import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import style from "../css/header.module.css"

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <div className={style.headerContainer}>
      <div
        style={{
          textTransform: "uppercase",
          fontSize: 14,
        }}
      >
        Makeup Artist
      </div>
      <Link className={style.headerTitleLink} to="/">
        <div className={`header-title ${style.headerTitle}`}>
          <p>J</p>
          <p>a</p>
          <p>c</p>
          <p>k</p>
          <p>i</p>
          <p>e</p>
          <p>S</p>
          <p>u</p>
          <p>e</p>
        </div>
      </Link>
    </div>
    <nav className={style.nav}>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/pricing">Pricing</Link>
      <Link to="/contact">Contact</Link>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
